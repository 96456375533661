"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var randombytes = require("randombytes");
function getRandomBytes(bytes) {
  return new Promise(function (resolve, reject) {
    randombytes(bytes, function (err, resp) {
      if (err) {
        reject(err);
        return;
      }
      resolve(resp);
    });
  });
}
exports.getRandomBytes = getRandomBytes;
function getRandomBytesSync(bytes) {
  return randombytes(bytes);
}
exports.getRandomBytesSync = getRandomBytesSync;
