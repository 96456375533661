"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
function createHashFunction(hashConstructor) {
  return function (msg) {
    var hash = hashConstructor();
    hash.update(msg);
    return Buffer.from(hash.digest());
  };
}
exports.createHashFunction = createHashFunction;
